import image from './PP.png'
import imagemap from './ppmap.png'


const PP = () => {
    return (
        <>
            <div className='allimage'>
                <img src={image} className='imagepp' />
                <img src={imagemap} className='imagepp1'/>
            </div>
        </>
    )
}

export default PP

