import React, { useEffect, useRef } from 'react';
// import image from './IFC.png'

const IFC = () => {

    const iframeRef = useRef(null);

    useEffect(() => {

        const iframe = iframeRef.current;

        // console.log("USE EFFECT", iframe);

        const handleResize = () => {
            if (iframe) {
                const iframeDocument = iframe.contentDocument || iframe.contentWindow.document;
                iframe.style.height = `${iframeDocument.body.scrollHeight}px`;

                iframe.addEventListener('load', handleResize);
                window.addEventListener('resize', handleResize);
            }
        };
        return () => {
            if (iframe) {
                iframe.removeEventListener('load', handleResize);
                window.removeEventListener('resize', handleResize);
            }
        };
    }, [iframeRef]);

    return (
        <>
            <div className="ifc-container">
                {/* <h1>IFC</h1> */}
                {/* <img src={image} className='imageifc'/> */}
                <iframe
                className='ifc-frame'
                    ref={iframeRef}
                    src="https://customdemo.develoh.com/DevelohEditor/Westenenkerweg42/"
                    // width="100%"
                    width="800px"
                    height="700px"
                    style={{ border: 'none' }}
                    title="Custom Demo"
                    scrolling='no'
                ></iframe>
            </div>
        </>
    )
}

export default IFC
