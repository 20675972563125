import image from './login.PNG'
import { useNavigate } from 'react-router-dom';
import image1 from './CZ.png'
import image3 from './eAG.png'

const Login = () => {
    const navigate = useNavigate();

    const buttonStyle = {
        position: 'absolute',
        backgroundColor: 'blue',
        width: '260px',
        height: '40px',
        color: 'white',
        border: 'none',
        padding: '10px 20px',
        cursor: 'pointer',
        top: '265px',
        left: '43.5%',
        borderRadius: '0', // No rounded corners
    };

    // const buttonStyle = {
    //     backgroundColor: 'blue',
    //     width: '210px',
    //     height: '40px',
    //     color: 'white',
    //     border: 'none',
    //     padding: '10px 20px',
    //     cursor: 'pointer',
    //     top: '0px',
    //     left: '0%',
    //     borderRadius: '0', // No rounded corners
    // };

    const containerStyle = {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        height: '100vh', // Full height of the viewport to demonstrate the alignment at the top
        width: '100vw'
    };

    const handleLogin = () => {
        // Add your login logic here (e.g., API call, authentication)

        // After successful login, navigate to the dashboard
        navigate('/pp');
    };

    return (
        <>
             <div style={containerStyle}>

                <img src={image1} style={{ width: '200px', height: 'auto' }} />
                <img src={image}/>
                <img src={image3} style={{ width: '200px', height: 'auto' }}/>
                <button style={buttonStyle} onClick={handleLogin}>
                    Continue
                </button>
            </div>
        </>
    )
}

export default Login