import { Outlet } from "react-router-dom"
import Sidebar from "../../components/Sidebar"

const Layout = () => {
    return (
        <>
            <div className="container">
                <Sidebar />
                <div className="main-content">
                    <main>
                        <Outlet />
                    </main>
                </div>
            </div>
        </>
    )
}

export default Layout