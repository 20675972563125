import { NavLink } from 'react-router-dom';
import image from './CZ.png'
import image1 from './CZ1.png'
import image2 from './eAG.png'

const activeLink = {
    fontWeight: 'bold',
    color: 'blue',
}

const Sidebar = () => {
    return (
        <div className="sidebar">
            <img src={image} className='logoCZ' />
            <img src={image1} className='logoCZ' />
            {/* <img src={image} style={{ width: '150%', height: 'auto' }} /> */}
            <ul className="sidebar-content">
                <li><NavLink to="/pp" activeclassname="active-link">Projektpilot</NavLink></li>
                <li><NavLink to="/ifc" activeclassname="active-link">IFC Kontrolle</NavLink></li>
                <li><NavLink to="/dashboard" activeclassname="active-link">Dashboard</NavLink></li>
            </ul>
            <img src={image2} className='logoCZ1' />
        </div>
    )
}

export default Sidebar

// .active-link {
//     font-weight: bold;
//     color: blue;
// }