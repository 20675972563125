import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Dashboard from './Dashboard';
import Login from './Login';
import NotFound from './NotFound';
import Layout from './Layout';
import IFC from './IFC';
import PP from './PP';


const Router = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Login />} />
                <Route element={<Layout />} >

                    <Route path="/pp" element={<PP />} />
                    <Route path="/ifc" element={<IFC />} />
                    <Route path="/dashboard" element={<Dashboard />} />
                    <Route path="*" element={<NotFound />} />
                </Route>
            </Routes>
        </BrowserRouter >
    )
}

export default Router;