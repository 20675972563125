import React from 'react';
import GridLayout from 'react-grid-layout';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import { Line, Bar, Doughnut } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    ArcElement,
    BarElement,
    LineElement,
    Title,
    Tooltip,
    Legend
} from 'chart.js';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    BarElement,
    LineElement,
    ArcElement,
    Title,
    Tooltip,
    Legend
);

// import { Chart as ChartJS, BarElement, LineElement, CategoryScale, LinearScale, PointElement, Legend, Title, Tooltip } from 'chart.js';
// import { Chart } from 'react-chartjs-2';

// Register the components
ChartJS.register(BarElement, LineElement, CategoryScale, LinearScale, PointElement, Legend, Title, Tooltip);

const Dashboard = () => {
    const layout = [
        { i: 'chart1', x: 0, y: 0, w: 2, h: 1.2 },
        { i: 'chart2', x: 3, y: 0, w: 4, h: 1.2 },
        { i: 'chart3', x: 8, y: 0, w: 1.4, h: 1.2 },

        { i: 'chart4', x: 0, y: 2, w: 2, h: 1.2 },
        { i: 'chart5', x: 3, y: 2, w: 4, h: 1.2 },
        { i: 'chart6', x: 8, y: 2, w: 1.4, h: 1.2 },
        
        { i: 'chart7', x: 0, y: 4, w: 2, h: 1.2 },
        { i: 'chart8', x: 3, y: 4, w: 4, h: 1.2 },
        { i: 'chart9', x: 8, y: 4, w: 1.4, h: 1.2 },

        { i: 'chart10', x: 0, y: 6, w: 4.5, h: 2 },
        { i: 'chart11', x: 3, y: 6, w: 4.5, h: 2 },
        { i: 'chart12', x: 8, y: 6, w: 4.5, h: 2 },
        



    ];

    const lineData = {
        labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
        datasets: [
            { label: 'Line1 Dataset', backgroundColor: '#FF0000', borderColor: '#FF0000', borderWidth: 1, data: [65, 59, 80, 70, 56, 55, 40] },
            { label: 'Line2 Dataset', backgroundColor: '#0000FF', borderColor: '#0000FF', borderWidth: 1, data: [67, 99, 60, 41, 36, 45, 60] },
            { label: 'Line3 Dataset', backgroundColor: '#66BB55', borderColor: '#66BB55', borderWidth: 1, data: [55, 31, 70, 33, 27, 62, 71] },
        ]
    };

    const lineData2 = {
        labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
        datasets: [
            { label: 'Line1 Dataset', backgroundColor: '#FF0000', borderColor: '#FF0000', borderWidth: 1, data: [55, 49, 70, 60, 56, 59, 60] },
            { label: 'Line2 Dataset', backgroundColor: '#0000FF', borderColor: '#0000FF', borderWidth: 1, data: [57, 69, 65, 51, 36, 48, 40] },
            { label: 'Line3 Dataset', backgroundColor: '#66BB55', borderColor: '#66BB55', borderWidth: 1, data: [45, 41, 70, 64, 27, 64, 51] },
        ]
    };

    const lineData3 = {
        labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
        datasets: [
            { label: 'Line1 Dataset', backgroundColor: '#FF0000', borderColor: '#FF0000', borderWidth: 1, data: [45, 80, 59, 70, 51, 55, 70] },
            { label: 'Line2 Dataset', backgroundColor: '#0000FF', borderColor: '#0000FF', borderWidth: 1, data: [47, 75, 69, 41, 46, 45, 50] },
            { label: 'Line3 Dataset', backgroundColor: '#66BB55', borderColor: '#66BB55', borderWidth: 1, data: [45, 70, 41, 39, 27, 62, 71] },
        ]
    };

    const barData = {
        labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
        datasets: [
            {
                label: 'Bar Dataset',
                backgroundColor: ['#FF0000', '#0000FF', '#66BB55'],
                hoverBackgroundColor: ['#FF0000', '#0000FF', '#66BB55'],
                borderWidth: 1,
                data: [91, 45, 60]
            },
        ]
    };

    const mixedData = {
        labels: ['January', 'February', 'March'],
        datasets: [
            {
            //     type: 'line',
            //     label: 'Line Dataset',
            //     borderColor: 'black',
            //     data: [30, 30, 30 ],
            //     pointRadius: 0,    

            // },
            // {
            //     type: 'bar',
                label: 'Bar Dataset',
                backgroundColor: ['#FF0000', '#0000FF', '#66BB55'],
                hoverBackgroundColor: ['#FF0000', '#0000FF', '#66BB55'],
                // borderWidth: 1,
                data: [61, 35, 40]
            } 
        ]
    };

    const mixedData2 = {
        labels: ['January', 'February', 'March'],
        datasets: [
            {
            //     type: 'line',
            //     label: 'Line Dataset',
            //     borderColor: 'black',
            //     data: [20, 20, 20 ],
            //     pointRadius: 0,    

            // },
            // {
            //     type: 'bar',
                label: 'Bar Dataset',
                backgroundColor: ['#FF0000', '#0000FF', '#66BB55'],
                hoverBackgroundColor: ['#FF0000', '#0000FF', '#66BB55'],
                // borderWidth: 1,
                data: [41, 25, 18]
            } 
        ]
    };

    const mixedData3 = {
        labels: ['January', 'February', 'March'],
        datasets: [
            {
            //     type: 'line',
            //     label: 'Line Dataset',
            //     borderColor: 'black',
            //     data: [20, 20, 20],
            //     pointRadius: 0,    

            // },
            // {
            //     type: 'bar',
                label: 'Bar Dataset',
                backgroundColor: ['#FF0000', '#0000FF', '#66BB55'],
                hoverBackgroundColor: ['#FF0000', '#0000FF', '#66BB55'],
                // borderWidth: 1,
                data: [41, 35, 55]
            } 
        ]
    };
    
    const doughnutData = {
        labels: ['1 - Aushub Tiefbau', '2 - Ausbauten/Innent...', '3 - Gebäude Teil 1 (Decken...', '4 - Gebäude Teil 2 (Stütz..', '5 - Gebäudetechnik', '6 - PV Anlage'],
        datasets: [
            {
                label: 'Doughnut Dataset',
                data: [300, 50, 100, 150, 200, 40],
                backgroundColor: ['#009933', '#000', '#AAA', '#66BB55', '#339999', '#00CC33'],
                hoverBackgroundColor: ['#009933', '#000', '#AAA', '#66BB55', '#339999', '#00CC33'],
            },
        ],
    };

    const doughnutData2 = {
        labels: ['1 - Aushub Tiefbau', '2 - Ausbauten/Innent...', '3 - Gebäude Teil 1 (Decken...', '4 - Gebäude Teil 2 (Stütz..', '5 - Gebäudetechnik', '6 - PV Anlage'],
        datasets: [
            {
                label: 'Doughnut Dataset',
                data: [200, 150, 80, 60, 20, 40],
                backgroundColor: ['#009933', '#000', '#AAA', '#66BB55', '#339999', '#00CC33'],
                hoverBackgroundColor: ['#009933', '#000', '#AAA', '#66BB55', '#339999', '#00CC33'],
            },
        ],
    };

    const doughnutData3 = {
        labels: ['1 - Aushub Tiefbau', '2 - Ausbauten/Innent...', '3 - Gebäude Teil 1 (Decken...', '4 - Gebäude Teil 2 (Stütz..', '5 - Gebäudetechnik', '6 - PV Anlage'],
        datasets: [
            {
                label: 'Doughnut Dataset',
                data: [120, 20, 140, 50, 260, 100],
                backgroundColor: ['#009933', '#000', '#AAA', '#66BB55', '#339999', '#00CC33'],
                hoverBackgroundColor: ['#009933', '#000', '#AAA', '#66BB55', '#339999', '#00CC33'],
            },
        ],
    };

    const doughnutTopData = {
        labels: [],//'Red', ,'Gray'],
        datasets: [
            {
                label: 'Doughnut Dataset',
                data: [200, 3, 50, 50,],
                backgroundColor: ['#FF0000', '#0000FF', '#FF0000', '#AAA'],
                hoverBackgroundColor: ['#FF0000', '#0000FF', '#FF0000', '#AAA'],
            },
        ],
    };

    const doughnutTopData2= {
        labels: [],
        datasets: [
            {
                label: 'Doughnut Dataset',
                data: [170, 3, 10, 50,],
                backgroundColor: ['#FF0000', '#0000FF', '#FF0000', '#AAA'],
                hoverBackgroundColor: ['#FF0000', '#0000FF', '#FF0000', '#AAA'],
            },
        ],
    };

    const doughnutTopData3 = {
        labels: [],
        datasets: [
            {
                label: 'Doughnut Dataset',
                data: [60, 3, 10, 150,],
                backgroundColor: ['#FF0000', '#0000FF', '#FF0000', '#AAA'],
                hoverBackgroundColor: ['#FF0000', '#0000FF', '#FF0000', '#AAA'],
            },
        ],
    };

    const doughnutTopOptions = {
        cutout: '75%', // Adjust the cutout to make it a semi-circle (50% for half-circle)
        rotation: -90,//Math.PI, // Rotate the chart to start from the top
        circumference: 180,//Math.PI, // Limit the circumference to half of the circle
        plugins: {
            legend: {
                display: true,
                position: 'top',
            },
        },
        borderWidth: 0,
    };

    const doughnutOptions = {
        
        borderWidth: 0,
        scales: {
            y: {
                beginAtZero: true,
                display: false,
            },
            x: {
                beginAtZero: true,
                display: false,
            }
        },

        plugins: {
            legend: {
                display: true, // Hide the legend
                position: 'left', // Adjust position of legend (top, bottom, left, right)
                labels: {
                    font: {
                        size: 12,
                    },
                    // padding: '10px',
                },
                margin: '10px',
            },
        },
        
        layout: {
            padding: 0, // Remove padding
        },

        responsive: true,
        maintainAspectRatio: false, // Adjust aspect ratio as needed
        // barThickness: 26,
    };

    const mixedOptions = {
        scales: {
            y: {
                beginAtZero: true,
                display: false,
            },
            x: {
                beginAtZero: true,
                display: false,
            }
        },

        plugins: {
            legend: {
                display: false, // Hide the legend
            },
        },
        elements: {
            line: {
                borderWidth: 3, // Hide the lines connecting points in a line chart
                
            },
            bar: {
                borderWidth: 0, // Hide the borders of bars in a bar chart                
            },
        },
        layout: {
            padding: 0, // Remove padding
        },
        responsive: true,
        maintainAspectRatio: false, // Adjust aspect ratio as needed
        barThickness: 26,
    };

    const options = {
        scales: {
            x: {
                type: 'category', // Ensure you use 'category' type for x-axis in Chart.js v3
                labels: lineData.labels,
                display: false,
            },
            y: {
                beginAtZero: true,
                display: false,
            },
        },
        plugins: {
            legend: {
                display: false, // Hide the legend
            },
        },
        elements: {
            line: {
                borderWidth: 0, // Hide the lines connecting points in a line chart
            },
            bar: {
                borderWidth: 0, // Hide the borders of bars in a bar chart
            },
        },
        layout: {
            padding: 0, // Remove padding
        },
        responsive: true,
        maintainAspectRatio: true, // Adjust aspect ratio as needed
    };

    return (
        <div>
            <h2>Recyclingzentrum Juch - Areal</h2>
            <GridLayout className="layout" layout={layout} cols={12} rowHeight={150} width={1200}>
                <div key="chart1" className="grid-item">
                    <Doughnut data={doughnutTopData} options={doughnutTopOptions} />
                </div>
                <div key="chart2" className="grid-item">
                    <Line data={lineData} options={options} />
                </div>
                <div key="chart3" className="grid-item">
                    <Bar data={mixedData} options={mixedOptions} />
                    {/* <Line data={mixedData} options={mixedOptions} /> */}
                </div>

                <div key="chart4" className="grid-item">
                    <Doughnut data={doughnutTopData2} options={doughnutTopOptions} />
                </div>
                <div key="chart5" className="grid-item">
                    <Line data={lineData2} options={options} />
                </div>
                <div key="chart6" className="grid-item">                    
                    <Bar data={mixedData2} options={mixedOptions} />
                </div>

                <div key="chart7" className="grid-item">
                    <Doughnut data={doughnutTopData3} options={doughnutTopOptions} />
                </div>

                <div key="chart8" className="grid-item">
                    <Line data={lineData3} options={options} />
                </div>
                
                <div key="chart9" className="grid-item">
                    <Bar data={mixedData3} options={mixedOptions} />
                </div>
                
 
                <div key="chart10" className="grid-item">
                    <Doughnut data={doughnutData} options={doughnutOptions}/>
                </div>

                <div key="chart11" className="grid-item">
                    <Doughnut data={doughnutData2} options={doughnutOptions}/>
                </div>

                <div key="chart12" className="grid-item">
                    <Doughnut data={doughnutData3} options={doughnutOptions}/>
                </div>

            </GridLayout>
        </div>
    );
};

export default Dashboard;